@use "sass:map";

@keyframes csSelectInitLoading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

select.cs-select-init,
select.cs-select-init[multiple],
.cs-select-init {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0.1923em;
  width: 250px;
  max-width: 100%;
  height: 1em;
  background-color: var(--cs-background-color);
  color: transparent;
  appearance: none;
  pointer-events: none;
  box-shadow: 0 1px 2px var(--cs-overlay-08-color);

  &:before {
    content: "";
    display: block;
    width: 150%;
    height: 1em;
    animation: csSelectInitLoading 1s ease-out infinite;
    background: linear-gradient(
      90deg,
      transparent 0%,
      var(--cs-overlay-025-color) 35%,
      var(--cs-overlay-05-color) 65%,
      transparent 100%
    );
  }

  * {
    display: none;
  }
}

.cs-select-handle {
  width: 100%;
  font-size: inherit;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-radius: 0.1923em;
  min-height: 1em;
  box-sizing: border-box;
  background-color: var(--cs-background-color);
  box-shadow: 0 1px 2px var(--cs-overlay-08-color);
  cursor: pointer;
  transition: border 0.1s ease-out, background-color 0.1s ease-out, box-shadow 0.2s ease-out;
  overflow: hidden;

  &:focus,
  &:focus-visible {
    outline: 1.5px solid var(--cs-highlight-color);
    outline-offset: -1.5px;
    box-shadow: 0 0 0.11538em var(--cs-highlight-50-color);
  }

  &:not(.-show-selected) {
    justify-content: space-between;
  }

  * {
    pointer-events: none;
  }

  .cs-select-clear,
  .cs-select-reset {
    pointer-events: all;
  }
}

.cs-select-placeholder,
.cs-select-selected-options {
  padding: 0 0.32727em;
}

.cs-select-placeholder {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 1em;
  flex: 1;
  color: var(--cs-overlay-50-color);

  span {
    font-size: 0.28846em;
  }
}

.cs-select-placeholder:first-child,
.cs-select-selected-options:first-child {
  padding-left: 0.32727em !important;
}

.cs-select-selected-options {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.09615em;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 0.32727em;
  padding-right: 0.32727em;
  position: relative;
}

.cs-select-selected-option {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  line-height: 1em;
}

.cs-select-selected-option-text {
  font-size: 0.28846em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--cs-overlay-color);
}

.cs-select-selected-options-count {
  background: linear-gradient(90deg, var(--cs-background-0-color) 0%, var(--cs-background-color) 30%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.17308em;
  padding-left: 0.5em;
  position: absolute;
  z-index: 1;
  top: 0.23em;
  right: 0;
  bottom: 0.23em;
  
  span {
    border-radius: 1.04543em;
    background-color: var(--cs-highlight-bg-20-color);
    min-width: 2.09086em;
    height: 2.09086em;
    line-height: 1;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cs-highlight-color);
    font-size: 0.21154em;
    padding: 0 0.7em;
  }
}

button.cs-select-clear,
button.cs-select-reset {
  width: 0.4423em;
  min-width: 0.4423em;
  height: 0.4423em;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  background-color: var(--cs-overlay-06-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  border: none;
  margin: 0;
  padding: 0;

  * {
    pointer-events: none;
  }

  svg {
    display: block;
    width: auto;
    height: 41.66513%;
    transition: fill 0.2s;
    fill: var(--cs-overlay-50-color);
  }

  &:hover,
  &:focus-visible {
    background-color: var(--cs-highlight-20-color);

    svg {
      fill: var(--cs-highlight-color);
    }
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:focus-visible {
    border: 1.5px solid var(--cs-highlight-color);
    outline: none !important;
    box-shadow: none;
  }
}

button.cs-select-reset {
  transition: transform 0.15s ease-out, opacity 0.15s, background-color 0.2s;
  transform: translateY(0.28181em) scale(1);
  opacity: 1;

  .cs-select.-empty & {
    transform: translateY(0.28181em) scale(0.75);
    opacity: 0;
    pointer-events: none;
  }
}

button.cs-select-clear {
  transition: background-color 0.2s;
}

button.cs-select-arrow {
  width: 0.92436em;
  min-width: 0.92436em;
  border-radius: 0 0.18181em 0.18181em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: inherit;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  pointer-events: none;

  svg {
    display: block;
    width: 0.26981em;
    height: 0.16363em;
    transform: translateY(0.41817em);
    fill: var(--cs-overlay-color);
  }
}

.cs-select-selected-option-images {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.32727em;
  width: max-content;
  font-size: inherit;
  position: relative;

  svg {
    fill: var(--cs-overlay-color);
  }
}

.cs-select-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.24818em;
  max-width: 0.50368em;
  height: 0.50368em;
}

.cs-select-image-animated,
.cs-select-icon-wrap {
  transition: transform 0.15s ease-out, opacity 0.15s;
}

.cs-select-icon {
  display: none !important;
}

.cs-select .cs-select-icon {
  display: block !important;
  max-width: 1em;
  height: 1em;
  // styles for font icons
  font-size: 0.50368em;
  color: var(--cs-overlay-color);
}

.cs-select-image-in,
.cs-select-image-out {
  max-width: 0.50368em;
  height: 0.50368em;
  margin-top: 0.24818em;
}

.cs-select-image-in {
  opacity: 1;
  transform: scale(1);
}

.cs-select-image-out {
  opacity: 0;
  transform: scale(0.85);
  position: absolute;
  top: 0;
  left: 0.32727em;
}

.cs-select-options-wrap {
  font-size: inherit;
  background-color: var(--cs-background-color);
  border-radius: 0.1923em;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: none;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
  position: fixed;
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transform: scaleY(0.97) translateY(-4px);
  transform-origin: top left;
  box-shadow: 0 0.14545em 0.36363em 0 var(--cs-overlay-10-color);

  &.-position-top {
    transform: scaleY(0.97) translateY(4px);
    transform-origin: bottom left;
    box-shadow: 0 -0.14545em 0.36363em 0 var(--cs-overlay-10-color);
  }

  &.-position-center {
    transform: scaleY(0.97);
    transform-origin: center;
    box-shadow: 0 0 0.36363em 0 var(--cs-overlay-10-color);
  }

  &.-scrolled .cs-select-search-input-wrap {
    box-shadow: 0 1px 2px var(--cs-overlay-05-color);
  }

  .cs-select.-open & {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
}

.cs-select-options {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cs-select-option .-highlight {
  background-color: var(--cs-highlight-15-color);
  color: var(--cs-highlight-color);
}

.cs-select-option button {
  display: block;
  padding: 0.6em 0.93333em;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 0.28846em;
  font-family: inherit;
  color: var(--cs-overlay-color);
  text-align: left;
  border-radius: 0.5em;

  * {
    pointer-events: none;
    user-select: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible,
  &:hover {
    background-color: var(--cs-highlight-06-color);
  }

  &:focus-visible {
    color: var(--cs-highlight-color);
    outline: 1.5px solid var(--cs-highlight-color);
    outline-offset: -1.5px;
  }
}

.cs-select-option.-selected button {
  background-color: var(--cs-highlight-06-color);
}

.cs-select-option-checkbox {
  position: absolute;
  top: 50%;
  left: 0.25035em;
  border-radius: 0.07692em;
  width: 0.34615em;
  height: 0.34615em;
  opacity: 1;
  transform: translateY(-50%);
  background-color: var(--cs-overlay-05-color);
  transition: background-color 0.15s ease-out, border 0.15s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  
  .cs-select-option button:focus-visible + &,
  .cs-select-option button:hover + & {
    background-color: var(--cs-highlight-10-color);
  }

  .cs-select-option.-selected & {
    background-color: var(--cs-highlight-color) !important;
  }
}

.cs-select-option-checkbox svg {
  content: "";
  display: block;
  width: 0.23077em;
  height: 0.23077em;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.15s, transform 0.15s;
  fill: var(--cs-highlight-bg-6-color);

  .cs-select-option.-selected & {
    opacity: 1;
    transform: scale(1);
  }
}

button.cs-select-search-clear.cs-select-clear {
  position: absolute;
  top: 50%;
  right: 0.32727em;
  transition: transform 0.15s ease-out, opacity 0.15s, background-color 0.2s;
  transform: translateY(-50%) scale(0.75);
  z-index: 2;
  opacity: 0;
  display: flex;
  pointer-events: none;
}

.cs-select-search-input-wrap {
  background-color: var(--cs-background-color);
  box-shadow: 0 0 transparent;
  transition: box-shadow 0.2s ease-out, border-bottom 0.2s ease-out;
  position: relative;
  font-size: inherit;
  border-bottom: 1px solid var(--cs-overlay-10-color);
  z-index: 1;

  &.-not-empty button.cs-select-search-clear {
    pointer-events: all;
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

input[type="text"].cs-select-search-input {
  display: block;
  width: 100%;
  padding: 0 3.8024em 0 1.2em;
  font-size: 0.28846em;
  line-height: 1;
  margin: 0;
  border-radius: 0.66667em 0.66667em 0 0;
  height: 3.06667em;
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 1;
  color: var(--cs-overlay-color);

  &:focus {
    outline: none !important;
    box-shadow: 0 1.5px var(--cs-highlight-color);
  }

  // WebKit, Blink, Edge
  &::-webkit-input-placeholder {
    color: var(--cs-overlay-50-color);
  }

  // Mozilla Firefox 19+
  &::-moz-placeholder {
    color: var(--cs-overlay-50-color);
    opacity: 1;
  }

  // Microsoft Edge
  &::-ms-input-placeholder {
    color: var(--cs-overlay-50-color);
  }

  // Most modern browsers
  &::placeholder {
    color: var(--cs-overlay-50-color);
  }
}

.cs-select-search-glass {
  position: absolute;
  top: 50%;
  right: 0.32727em;
  transition: transform 0.15s ease-out, opacity 0.15s;
  transform: translateY(-50%) scale(1);
  z-index: 0;
  width: 0.30769em;
  height: 0.30769em;
  display: flex;

  .cs-select-search-input-wrap.-not-empty & {
    opacity: 0;
    transform: translateY(-50%) scale(0.75);
  }

  svg {
    width: 100%;
    height: 100%;
    fill: var(--cs-overlay-50-color);
  }
}

.cs-select-scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: var(--cs-overlay-color) transparent;
  scrollbar-width: thin;
  overscroll-behavior: contain;

  .cs-select-options & {
    padding: 0.07692em;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--cs-overlay-40-color);
    border-radius: 4px;
  }
}

.cs-select-no-options,
.cs-select-no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0.66667em;
  font-size: 0.28846em;
  color: var(--cs-overlay-color);
}

.cs-select {
  display: inline-block;
  user-select: none;
  position: relative;
  max-width: 100%;

  &,
  * {
    box-sizing: border-box;
  }

  &.-fullwidth {
    width: 100%;
  }

  &.-empty {
    .cs-select-placeholder {
      display: flex;
    }
    .cs-select-selected-options {
      display: none;
    }
  }

  &.-clearable:not(.-arrow) button.cs-select-reset {
    margin-right: 0.32727em;
  }

  &.-clearable:not(.-show-selected) .cs-select-selected-option-images {
    margin-right: 0.32727em;
  }

  &.-clearable:not(.-show-selected):not(.-show-option-images) .cs-select-reset {
    margin-left: 0.32727em;
  }

  &.-show-option-images {
    .cs-select-placeholder,
    .cs-select-selected-options {
      padding-left: 0.25em;
    }
  }

  &.-arrow {
    .cs-select-placeholder,
    .cs-select-selected-options {
      padding-right: 0;
    }
  }

  &.-clearable {
    .cs-select-placeholder,
    .cs-select-selected-options {
      padding-right: 0.32727em;
    }
  }

  &.-clearable.-show-option-images {
    .cs-select-placeholder,
    .cs-select-selected-options {
      padding-right: 0.25em;
    }
  }

  &.-single.-fullwidth .cs-select-selected-option {
    flex: 1;
    justify-content: space-between;
  }

  &.-multiple .cs-select-selected-options {
    padding-top: 0.23365em;
    padding-bottom: 0.23365em;
  }

  &.-multiple .cs-select-selected-options.-tag-summary {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  &.-multiple .cs-select-selected-option {
    background-color: var(--cs-overlay-06-color);
    border-radius: 0.26923em;
    padding-left: 0.21154em;
    padding-right: 0.21154em;
    line-height: 0.53269em;
    height: 0.53269em;
    min-height: 0.53269em;
    column-gap: 0.03846em;
  }

  &.-multiple .cs-select-selected-options.-tag-summary .cs-select-selected-option {
    overflow: visible;
  }

  &.-multiple .cs-select-selected-option-text {
    font-size: 0.26923em;
  }

  &.-multiple .cs-select-selected-options.-tag-summary .cs-select-selected-option-text {
    text-overflow: initial;
    overflow: visible;
  }

  &.-multiple .cs-select-selected-option .cs-select-clear {
    width: 0.38461em;
    min-width: 0.38461em;
    height: 0.38461em;
    background-color: var(--cs-background-color);
    transform: translateX(0.11538em);

    &:hover,
    &:focus-visible {
      background-color: var(--cs-highlight-20-color);
    }
  }

  &.-multiple .cs-select-option {
    position: relative;
  }

  &.-multiple .cs-select-option button {
    padding-left: 0.92376em;
  }

  select {
    position: absolute;
    appearance: none;
    pointer-events: none;
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }
}
