#profile-menu {
  .cs-select-handle {
    border-radius: 1em;
  }

  .cs-select-option.-selected button {
    background-color: transparent;
  }

  .cs-select-option button:focus-visible,
  .cs-select-option button:hover {
    background-color: var(--cs-highlight-06-color);
  }

  .notification-bubble {
    display: inline-block;
    border-radius: 10px;
    font-size: 9px;
    color: var(--cs-background-color);
    padding: 3px 5px;
    font-weight: 600;
    transform: translateY(-1px);
    margin-left: 2px;
    background-color: var(--cs-highlight-color);
  }
}