@import "https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,400,0,0";
* {
  box-sizing: border-box;
  font-family: SUSE;
}

body {
  color: #1a1a1b;
  background-color: #fff;
  width: 1300px;
  margin: 0 auto;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
}

header {
  background-color: #f3f4f7;
}

h1 {
  color: #fff;
  text-align: center;
  background-color: #1a1a1b;
  width: 554px;
  margin: 0;
  padding: 20px 50px;
  font-size: 27px;
}

h2 {
  margin: 0;
}

h4 {
  margin: 0 0 15px;
  font-size: 16px;
  font-weight: 600;
}

aside, .example-preview {
  flex-direction: column;
  justify-content: center;
  padding: 100px 50px;
  display: flex;
}

aside {
  color: #fff;
  background-color: #1a1a1b;
  align-items: flex-start;
  row-gap: 10px;
  width: 554px;
}

.example-preview {
  flex: 1;
}

section {
  background-color: #f3f4f7;
  border-top: 1px solid #f3f4f7;
  flex-direction: row;
  display: flex;
}

.select-wrapper {
  text-align: center;
  width: 382px;
  max-width: 100%;
  margin: 0 auto;
}

p.example-desc {
  margin-top: 7px;
  font-size: 15px;
  line-height: 1.6;
}

.edit-example-wrap {
  margin-top: 20px;
}

.edit-buttons {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

a.edit-example {
  color: #fff;
  white-space: nowrap;
  border: none;
  width: 203px;
  margin: 0;
  padding: 16px 45px 16px 20px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

a.edit-example .material-symbols-rounded {
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
}

a.edit-example:before {
  content: "";
  border: 1px solid #fff;
  border-radius: 8px;
  transition: border-width .1s;
  display: block;
  position: absolute;
  inset: 0;
}

a.edit-example:hover:before {
  border-width: 3px;
}

.switch {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 8px;
  flex-direction: row;
  height: 48px;
  display: flex;
  overflow: hidden;
}

button.switch-option {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  width: 33.33%;
  margin: 0;
  padding: 5px 15px;
  font-size: 16px;
}

button.switch-option.-selected {
  color: #1a1a1b;
  background-color: #fff;
}

.cs-select-init, .cs-select {
  font-size: 45px;
}

@media screen and (width >= 480px) {
  .cs-select-init, .cs-select {
    font-size: 52px;
  }
}

.cs-select-init, .cs-select-init.-theme-light, .cs-select, .cs-select.-theme-light {
  --cs-background-color: #fff;
  --cs-highlight-color: #2f39be;
  --cs-overlay-color: #1a1a1b;
  --cs-background-0-color: #fff0;
  --cs-highlight-bg-6-color: #f3f3fb;
  --cs-highlight-bg-20-color: #d5d7f2;
  --cs-highlight-06-color: #2f39be0f;
  --cs-highlight-10-color: #2f39be1a;
  --cs-highlight-15-color: #2f39be26;
  --cs-highlight-17-color: #2f39be2b;
  --cs-highlight-20-color: #2f39be33;
  --cs-highlight-50-color: #2f39be80;
  --cs-overlay-025-color: #1a1a1b06;
  --cs-overlay-05-color: #1a1a1b0d;
  --cs-overlay-06-color: #1a1a1b0f;
  --cs-overlay-08-color: #1a1a1b14;
  --cs-overlay-10-color: #1a1a1b1a;
  --cs-overlay-15-color: #1a1a1b26;
  --cs-overlay-40-color: #1a1a1b66;
  --cs-overlay-50-color: #1a1a1b80;
  --cs-overlay-75-color: #1a1a1bbf;
}

.cs-select-init.-theme-dark, .cs-select.-theme-dark {
  --cs-background-color: #1a1a1b;
  --cs-highlight-color: #2f39be;
  --cs-overlay-color: #fff;
  --cs-background-0-color: #1a1a1b00;
  --cs-highlight-bg-6-color: #1b1c25;
  --cs-highlight-bg-20-color: #1e203c;
  --cs-highlight-06-color: #2f39be0f;
  --cs-highlight-10-color: #2f39be1a;
  --cs-highlight-15-color: #2f39be26;
  --cs-highlight-17-color: #2f39be2b;
  --cs-highlight-20-color: #2f39be33;
  --cs-highlight-50-color: #2f39be80;
  --cs-overlay-025-color: #ffffff06;
  --cs-overlay-05-color: #ffffff0d;
  --cs-overlay-06-color: #ffffff0f;
  --cs-overlay-08-color: #ffffff14;
  --cs-overlay-10-color: #ffffff1a;
  --cs-overlay-15-color: #ffffff26;
  --cs-overlay-40-color: #fff6;
  --cs-overlay-50-color: #ffffff80;
  --cs-overlay-75-color: #ffffffbf;
}

@keyframes csSelectInitLoading {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

select.cs-select-init, select.cs-select-init[multiple], .cs-select-init {
  background-color: var(--cs-background-color);
  color: #0000;
  appearance: none;
  pointer-events: none;
  width: 250px;
  max-width: 100%;
  height: 1em;
  box-shadow: 0 1px 2px var(--cs-overlay-08-color);
  border: none;
  border-radius: .1923em;
  margin: 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

select.cs-select-init:before, select.cs-select-init[multiple]:before, .cs-select-init:before {
  content: "";
  background: linear-gradient(90deg, transparent 0%, var(--cs-overlay-025-color) 35%, var(--cs-overlay-05-color) 65%, transparent 100%);
  width: 150%;
  height: 1em;
  animation: 1s ease-out infinite csSelectInitLoading;
  display: block;
}

select.cs-select-init *, select.cs-select-init[multiple] *, .cs-select-init * {
  display: none;
}

.cs-select-handle {
  width: 100%;
  font-size: inherit;
  box-sizing: border-box;
  background-color: var(--cs-background-color);
  min-height: 1em;
  box-shadow: 0 1px 2px var(--cs-overlay-08-color);
  cursor: pointer;
  border-radius: .1923em;
  flex-direction: row;
  align-items: stretch;
  transition: border .1s ease-out, background-color .1s ease-out, box-shadow .2s ease-out;
  display: flex;
  overflow: hidden;
}

.cs-select-handle:focus, .cs-select-handle:focus-visible {
  outline: 1.5px solid var(--cs-highlight-color);
  outline-offset: -1.5px;
  box-shadow: 0 0 .11538em var(--cs-highlight-50-color);
}

.cs-select-handle:not(.-show-selected) {
  justify-content: space-between;
}

.cs-select-handle * {
  pointer-events: none;
}

.cs-select-handle .cs-select-clear, .cs-select-handle .cs-select-reset {
  pointer-events: all;
}

.cs-select-placeholder, .cs-select-selected-options {
  padding: 0 .32727em;
}

.cs-select-placeholder {
  color: var(--cs-overlay-50-color);
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  line-height: 1em;
  display: none;
}

.cs-select-placeholder span {
  font-size: .28846em;
}

.cs-select-placeholder:first-child, .cs-select-selected-options:first-child {
  padding-left: .32727em !important;
}

.cs-select-selected-options {
  box-sizing: border-box;
  flex-flow: wrap;
  flex: 1;
  gap: .09615em;
  padding-left: .32727em;
  padding-right: .32727em;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cs-select-selected-option {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  display: flex;
  overflow: hidden;
}

.cs-select-selected-option-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--cs-overlay-color);
  font-size: .28846em;
  overflow: hidden;
}

.cs-select-selected-options-count {
  background: linear-gradient(90deg, var(--cs-background-0-color) 0%, var(--cs-background-color) 30%);
  z-index: 1;
  justify-content: center;
  align-items: center;
  padding-left: .5em;
  padding-right: .17308em;
  display: flex;
  position: absolute;
  top: .23em;
  bottom: .23em;
  right: 0;
}

.cs-select-selected-options-count span {
  background-color: var(--cs-highlight-bg-20-color);
  min-width: 2.09086em;
  height: 2.09086em;
  color: var(--cs-highlight-color);
  border-radius: 1.04543em;
  justify-content: center;
  align-items: center;
  padding: 0 .7em;
  font-size: .21154em;
  font-weight: 500;
  line-height: 1;
  display: flex;
}

button.cs-select-clear, button.cs-select-reset {
  cursor: pointer;
  background-color: var(--cs-overlay-06-color);
  width: .4423em;
  min-width: .4423em;
  height: .4423em;
  font-size: inherit;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

button.cs-select-clear *, button.cs-select-reset * {
  pointer-events: none;
}

button.cs-select-clear svg, button.cs-select-reset svg {
  width: auto;
  height: 41.6651%;
  fill: var(--cs-overlay-50-color);
  transition: fill .2s;
  display: block;
}

button.cs-select-clear:hover, button.cs-select-clear:focus-visible, button.cs-select-reset:hover, button.cs-select-reset:focus-visible {
  background-color: var(--cs-highlight-20-color);
}

button.cs-select-clear:hover svg, button.cs-select-clear:focus-visible svg, button.cs-select-reset:hover svg, button.cs-select-reset:focus-visible svg {
  fill: var(--cs-highlight-color);
}

button.cs-select-clear:focus, button.cs-select-reset:focus {
  border: none;
  outline: none;
}

button.cs-select-clear:focus-visible, button.cs-select-reset:focus-visible {
  border: 1.5px solid var(--cs-highlight-color);
  box-shadow: none;
  outline: none !important;
}

button.cs-select-reset {
  opacity: 1;
  transition: transform .15s ease-out, opacity .15s, background-color .2s;
  transform: translateY(.28181em)scale(1);
}

.cs-select.-empty button.cs-select-reset {
  opacity: 0;
  pointer-events: none;
  transform: translateY(.28181em)scale(.75);
}

button.cs-select-clear {
  transition: background-color .2s;
}

button.cs-select-arrow {
  width: .92436em;
  min-width: .92436em;
  font-size: inherit;
  pointer-events: none;
  background-color: #0000;
  border: none;
  border-radius: 0 .18181em .18181em 0;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

button.cs-select-arrow svg {
  width: .26981em;
  height: .16363em;
  fill: var(--cs-overlay-color);
  display: block;
  transform: translateY(.41817em);
}

.cs-select-selected-option-images {
  width: max-content;
  font-size: inherit;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: .32727em;
  display: flex;
  position: relative;
}

.cs-select-selected-option-images svg {
  fill: var(--cs-overlay-color);
}

.cs-select-icon-wrap {
  justify-content: center;
  align-items: center;
  max-width: .50368em;
  height: .50368em;
  margin-top: .24818em;
  display: flex;
}

.cs-select-image-animated, .cs-select-icon-wrap {
  transition: transform .15s ease-out, opacity .15s;
}

.cs-select-icon {
  display: none !important;
}

.cs-select .cs-select-icon {
  max-width: 1em;
  height: 1em;
  color: var(--cs-overlay-color);
  font-size: .50368em;
  display: block !important;
}

.cs-select-image-in, .cs-select-image-out {
  max-width: .50368em;
  height: .50368em;
  margin-top: .24818em;
}

.cs-select-image-in {
  opacity: 1;
  transform: scale(1);
}

.cs-select-image-out {
  opacity: 0;
  position: absolute;
  top: 0;
  left: .32727em;
  transform: scale(.85);
}

.cs-select-options-wrap {
  font-size: inherit;
  background-color: var(--cs-background-color);
  opacity: 0;
  z-index: 2147483647;
  transform-origin: 0 0;
  height: 0;
  box-shadow: 0 .14545em .36363em 0 var(--cs-overlay-10-color);
  border: none;
  border-radius: .1923em;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  transition: opacity .1s ease-out, transform .1s ease-out;
  display: flex;
  position: fixed;
  overflow: hidden;
  transform: scaleY(.97)translateY(-4px);
}

.cs-select-options-wrap.-position-top {
  transform-origin: 0 100%;
  box-shadow: 0 -.14545em .36363em 0 var(--cs-overlay-10-color);
  transform: scaleY(.97)translateY(4px);
}

.cs-select-options-wrap.-position-center {
  transform-origin: center;
  box-shadow: 0 0 .36363em 0 var(--cs-overlay-10-color);
  transform: scaleY(.97);
}

.cs-select-options-wrap.-scrolled .cs-select-search-input-wrap {
  box-shadow: 0 1px 2px var(--cs-overlay-05-color);
}

.cs-select.-open .cs-select-options-wrap {
  opacity: 1;
  transform: scaleY(1)translateY(0);
}

.cs-select-options {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.cs-select-option .-highlight {
  background-color: var(--cs-highlight-15-color);
  color: var(--cs-highlight-color);
}

.cs-select-option button {
  cursor: pointer;
  width: 100%;
  color: var(--cs-overlay-color);
  text-align: left;
  background-color: #0000;
  border: none;
  border-radius: .5em;
  margin: 0;
  padding: .6em .93333em;
  font-family: inherit;
  font-size: .28846em;
  display: block;
}

.cs-select-option button * {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.cs-select-option button:focus {
  outline: none;
}

.cs-select-option button:focus-visible, .cs-select-option button:hover {
  background-color: var(--cs-highlight-06-color);
}

.cs-select-option button:focus-visible {
  color: var(--cs-highlight-color);
  outline: 1.5px solid var(--cs-highlight-color);
  outline-offset: -1.5px;
}

.cs-select-option.-selected button {
  background-color: var(--cs-highlight-06-color);
}

.cs-select-option-checkbox {
  opacity: 1;
  background-color: var(--cs-overlay-05-color);
  pointer-events: none;
  border-radius: .07692em;
  justify-content: center;
  align-items: center;
  width: .34615em;
  height: .34615em;
  transition: background-color .15s ease-out, border .15s ease-out;
  display: flex;
  position: absolute;
  top: 50%;
  left: .25035em;
  transform: translateY(-50%);
}

.cs-select-option button:focus-visible + .cs-select-option-checkbox, .cs-select-option button:hover + .cs-select-option-checkbox {
  background-color: var(--cs-highlight-10-color);
}

.cs-select-option.-selected .cs-select-option-checkbox {
  background-color: var(--cs-highlight-color) !important;
}

.cs-select-option-checkbox svg {
  content: "";
  opacity: 0;
  width: .23077em;
  height: .23077em;
  fill: var(--cs-highlight-bg-6-color);
  transition: opacity .15s, transform .15s;
  display: block;
  transform: scale(.5);
}

.cs-select-option.-selected .cs-select-option-checkbox svg {
  opacity: 1;
  transform: scale(1);
}

button.cs-select-search-clear.cs-select-clear {
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: transform .15s ease-out, opacity .15s, background-color .2s;
  display: flex;
  position: absolute;
  top: 50%;
  right: .32727em;
  transform: translateY(-50%)scale(.75);
}

.cs-select-search-input-wrap {
  background-color: var(--cs-background-color);
  font-size: inherit;
  border-bottom: 1px solid var(--cs-overlay-10-color);
  z-index: 1;
  transition: box-shadow .2s ease-out, border-bottom .2s ease-out;
  position: relative;
  box-shadow: 0 0 #0000;
}

.cs-select-search-input-wrap.-not-empty button.cs-select-search-clear {
  pointer-events: all;
  opacity: 1;
  transform: translateY(-50%)scale(1);
}

input[type="text"].cs-select-search-input {
  z-index: 1;
  width: 100%;
  height: 3.06667em;
  color: var(--cs-overlay-color);
  background-color: #0000;
  border: none;
  border-radius: .66667em .66667em 0 0;
  margin: 0;
  padding: 0 3.8024em 0 1.2em;
  font-size: .28846em;
  line-height: 1;
  display: block;
  position: relative;
}

input[type="text"].cs-select-search-input:focus {
  box-shadow: 0 1.5px var(--cs-highlight-color);
  outline: none !important;
}

input[type="text"].cs-select-search-input::-webkit-input-placeholder {
  color: var(--cs-overlay-50-color);
}

input[type="text"].cs-select-search-input::-moz-placeholder {
  color: var(--cs-overlay-50-color);
  opacity: 1;
}

input[type="text"].cs-select-search-input::placeholder {
  color: var(--cs-overlay-50-color);
}

.cs-select-search-glass {
  z-index: 0;
  width: .30769em;
  height: .30769em;
  transition: transform .15s ease-out, opacity .15s;
  display: flex;
  position: absolute;
  top: 50%;
  right: .32727em;
  transform: translateY(-50%)scale(1);
}

.cs-select-search-input-wrap.-not-empty .cs-select-search-glass {
  opacity: 0;
  transform: translateY(-50%)scale(.75);
}

.cs-select-search-glass svg {
  width: 100%;
  height: 100%;
  fill: var(--cs-overlay-50-color);
}

.cs-select-scrollable {
  scrollbar-color: var(--cs-overlay-color) transparent;
  scrollbar-width: thin;
  overscroll-behavior: contain;
  overflow: hidden auto;
}

.cs-select-options .cs-select-scrollable {
  flex-direction: column;
  row-gap: 4px;
  padding: .07692em;
  display: flex;
}

.cs-select-scrollable::-webkit-scrollbar {
  width: 4px;
}

.cs-select-scrollable::-webkit-scrollbar-thumb {
  background-color: var(--cs-overlay-40-color);
  border-radius: 4px;
}

.cs-select-no-options, .cs-select-no-results {
  color: var(--cs-overlay-color);
  justify-content: center;
  align-items: center;
  padding: 2em .66667em;
  font-size: .28846em;
  display: flex;
}

.cs-select {
  -webkit-user-select: none;
  user-select: none;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.cs-select, .cs-select * {
  box-sizing: border-box;
}

.cs-select.-fullwidth {
  width: 100%;
}

.cs-select.-empty .cs-select-placeholder {
  display: flex;
}

.cs-select.-empty .cs-select-selected-options {
  display: none;
}

.cs-select.-clearable:not(.-arrow) button.cs-select-reset, .cs-select.-clearable:not(.-show-selected) .cs-select-selected-option-images {
  margin-right: .32727em;
}

.cs-select.-clearable:not(.-show-selected):not(.-show-option-images) .cs-select-reset {
  margin-left: .32727em;
}

.cs-select.-show-option-images .cs-select-placeholder, .cs-select.-show-option-images .cs-select-selected-options {
  padding-left: .25em;
}

.cs-select.-arrow .cs-select-placeholder, .cs-select.-arrow .cs-select-selected-options {
  padding-right: 0;
}

.cs-select.-clearable .cs-select-placeholder, .cs-select.-clearable .cs-select-selected-options {
  padding-right: .32727em;
}

.cs-select.-clearable.-show-option-images .cs-select-placeholder, .cs-select.-clearable.-show-option-images .cs-select-selected-options {
  padding-right: .25em;
}

.cs-select.-single.-fullwidth .cs-select-selected-option {
  flex: 1;
  justify-content: space-between;
}

.cs-select.-multiple .cs-select-selected-options {
  padding-top: .23365em;
  padding-bottom: .23365em;
}

.cs-select.-multiple .cs-select-selected-options.-tag-summary {
  flex-wrap: nowrap;
  overflow: hidden;
}

.cs-select.-multiple .cs-select-selected-option {
  background-color: var(--cs-overlay-06-color);
  border-radius: .26923em;
  column-gap: .03846em;
  height: .53269em;
  min-height: .53269em;
  padding-left: .21154em;
  padding-right: .21154em;
  line-height: .53269em;
}

.cs-select.-multiple .cs-select-selected-options.-tag-summary .cs-select-selected-option {
  overflow: visible;
}

.cs-select.-multiple .cs-select-selected-option-text {
  font-size: .26923em;
}

.cs-select.-multiple .cs-select-selected-options.-tag-summary .cs-select-selected-option-text {
  text-overflow: initial;
  overflow: visible;
}

.cs-select.-multiple .cs-select-selected-option .cs-select-clear {
  background-color: var(--cs-background-color);
  width: .38461em;
  min-width: .38461em;
  height: .38461em;
  transform: translateX(.11538em);
}

.cs-select.-multiple .cs-select-selected-option .cs-select-clear:hover, .cs-select.-multiple .cs-select-selected-option .cs-select-clear:focus-visible {
  background-color: var(--cs-highlight-20-color);
}

.cs-select.-multiple .cs-select-option {
  position: relative;
}

.cs-select.-multiple .cs-select-option button {
  padding-left: .92376em;
}

.cs-select select {
  appearance: none;
  pointer-events: none;
  opacity: 0;
  border: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

.mario-characters .cs-select-option button {
  font-size: inherit;
  text-align: left;
  border-radius: .14423em;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  column-gap: .34615em;
  min-height: 1.45192em;
  padding: .21153em .24038em;
  font-family: inherit;
  display: flex;
}

.mario-characters .cs-select-option .char-image {
  width: .96154em;
  min-width: .96154em;
}

.mario-characters .cs-select-option .char-image img {
  width: auto;
  max-height: 1.02885em;
  display: block;
}

.mario-characters .cs-select-option .char-image {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mario-characters .cs-select-option .char-excerpt-wrap {
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  display: flex;
}

.mario-characters .cs-select-option h6 {
  margin: 0;
  font-size: .28846em;
  font-weight: bold;
}

.mario-characters .cs-select-option p {
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
  font-size: .23077em;
  display: -webkit-box;
  overflow: hidden;
}

.mario-characters .cs-select.-multiple .cs-select-option button {
  padding-left: .92376em;
}

#profile-menu .cs-select-handle {
  border-radius: 1em;
}

#profile-menu .cs-select-option.-selected button {
  background-color: #0000;
}

#profile-menu .cs-select-option button:focus-visible, #profile-menu .cs-select-option button:hover {
  background-color: var(--cs-highlight-06-color);
}

#profile-menu .notification-bubble {
  color: var(--cs-background-color);
  background-color: var(--cs-highlight-color);
  border-radius: 10px;
  margin-left: 2px;
  padding: 3px 5px;
  font-size: 9px;
  font-weight: 600;
  display: inline-block;
  transform: translateY(-1px);
}
/*# sourceMappingURL=index.454a439d.css.map */
