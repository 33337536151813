@import url("https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,400,0,0");

* {
  font-family: "SUSE";
  box-sizing: border-box;
}

body {
  background-color: white;
  padding: 0;
  margin: 0 auto;
  color: #1a1a1b;
  font-size: 16px;
  line-height: 1.4;
  width: 1300px;
}

header {
  background-color: #f3f4f7;
}

h1 {
  margin: 0;
  font-size: 27px;
  background-color: #1a1a1b;
  color: white;
  padding: 20px 50px;
  width: 554px;
  text-align: center;
}

h2 {
  margin: 0;
}

h4 {
  margin: 0 0 15px;
  font-size: 16px;
  font-weight: 600;
}

aside,
.example-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 50px;
}

aside {
  background-color: #1a1a1b;
  color: white;
  width: 554px;
  align-items: flex-start;
  row-gap: 10px;
}

.example-preview {
  flex: 1;
}

section {
  display: flex;
  flex-direction: row;
  background-color: #f3f4f7;
  border-top: 1px solid #f3f4f7;
}

.select-wrapper {
  width: 382px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

p.example-desc {
  margin-top: 7px;
  font-size: 15px;
  line-height: 1.6;
}

.edit-example-wrap {
  margin-top: 20px;
}

.edit-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

a.edit-example {
  border: none;
  padding: 16px 45px 16px 20px;
  color: white;
  margin: 0;
  text-decoration: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  width: 203px;
  white-space: nowrap;

  .material-symbols-rounded {
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid white;
    transition: border-width 0.1s;
    border: 1px solid white;
    border-radius: 8px;
  }

  &:hover:before {
    border-width: 3px;
  }
}

.switch {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  border: 1px solid white;
  overflow: hidden;
  cursor: pointer;
  height: 48px;
}

button.switch-option {
  margin: 0;
  border: none;
  padding: 5px 15px;
  background-color: transparent;
  color: white;
  width: 33.33%;
  cursor: pointer;
  font-size: 16px;

  &.-selected {
    background-color: white;
    color: #1a1a1b;
  }
}
