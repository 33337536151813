@use "sass:map";
@use "sass:color";

$white: #ffffff;
$blue: #2f39be;
$obsidian: #1a1a1b;

$themes: (
  light: (
    background: $white,
    highlight: $blue,
    overlay: $obsidian,
  ),
  dark: (
    background: $obsidian,
    highlight: $blue,
    overlay: $white,
  )
);

@function getThemeColor($theme, $color) {
  @return map.get(map.get($themes, $theme), $color);
}

@mixin setThemeColorVariants($theme, $colors) {
  @each $name, $color in $colors {
    --cs-#{$name}-color: #{$color};
  }

  $background: getThemeColor($theme, "background");

  --cs-background-0-color: #{rgba($background, 0%)};

  $highlight: getThemeColor($theme, "highlight");

  --cs-highlight-bg-6-color: #{color.mix($highlight, $background, 6%)};
  --cs-highlight-bg-20-color: #{color.mix($highlight, $background, 20%)};

  --cs-highlight-06-color: #{rgba($highlight, 0.06)};
  --cs-highlight-10-color: #{rgba($highlight, 0.1)};
  --cs-highlight-15-color: #{rgba($highlight, 0.15)};
  --cs-highlight-17-color: #{rgba($highlight, 0.17)};
  --cs-highlight-20-color: #{rgba($highlight, 0.2)};
  --cs-highlight-50-color: #{rgba($highlight, 0.5)};

  $overlay: getThemeColor($theme, "overlay");

  --cs-overlay-025-color: #{rgba($overlay, 0.025)};
  --cs-overlay-05-color: #{rgba($overlay, 0.05)};
  --cs-overlay-06-color: #{rgba($overlay, 0.06)};
  --cs-overlay-08-color: #{rgba($overlay, 0.08)};
  --cs-overlay-10-color: #{rgba($overlay, 0.1)};
  --cs-overlay-15-color: #{rgba($overlay, 0.15)};
  --cs-overlay-40-color: #{rgba($overlay, 0.4)};
  --cs-overlay-50-color: #{rgba($overlay, 0.5)};
  --cs-overlay-75-color: #{rgba($overlay, 0.75)};
}

$selectHeightBreakpoints: (
  default: 45,
  480: 52,
);

@function getSelectHeight($breakpoint) {
  @return map.get($selectHeightBreakpoints, $breakpoint);
}

@mixin themeClasses {
  $i: 0;

  @each $theme, $colors in $themes {
    // Set theme color vars for the default theme
    @if ($i == 0) {
      &,
      &.-theme-#{$theme} {
        @include setThemeColorVariants($theme, $colors);
      }
    } @else {
      &.-theme-#{$theme} {
        @include setThemeColorVariants($theme, $colors);
      }
    }

    $i: $i + 1;
  }
}

.cs-select-init,
.cs-select {
  /**
   * Set the font size for different breakpoints. The font-size, set in pixels,
   * represents the height of the select handle, and all other elements use an
   * em value to scale appropriately relative to the handle.
   */

  font-size: #{getSelectHeight(default)}px;

  @each $bp, $height in map.remove($selectHeightBreakpoints, default) {
    @media screen and (min-width: #{$bp}px) {
      font-size: #{$height}px;
    }
  }

  @include themeClasses;
}
