.mario-characters .cs-select-option {
  button {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    padding: 0.21153em 0.24038em;
    column-gap: 0.34615em;
    font-size: inherit;
    font-family: inherit;
    text-align: left;
    min-height: 1.45192em;
    border-radius: 0.14423em;
  }

  .char-image {
    width: 0.96154em;
    min-width: 0.96154em;
  }

  .char-image img {
    display: block;
    max-height: 1.02885em;
    width: auto;
  }

  .char-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .char-excerpt-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }

  h6 {
    font-size: 0.28846em;
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: 0.23077em;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }
}

.mario-characters .cs-select.-multiple .cs-select-option button {
  padding-left: 0.92376em;
}
